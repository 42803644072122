@import "index.scss";

.strava-btn {
    &:hover {
        cursor: pointer;
    }
}

.login-container {

    justify-content: center;
    margin: 1em;
    gap: 1em;
    background-color: transparent;

}

.login-polyline-container {
    background-color: $purple;
    color: transparent;

    h1 {
        font-size: 40px;
        color: white;
        background-color: transparent;
    }
    p {
        margin: 0;
    }
}

.strava-login-container {
    color: white;


    padding: 1em;
    text-align: center;
}

@media only screen and (min-width: $medium-screen) {
    .login-container {
        margin: 3em;
        gap: 3em;

    }
    .strava-login-container {
        padding: 3em;
    }
}

@media only screen and (min-width: $big-screen) {
    .login-container {
        grid-template-columns: 1fr 400px;
        margin: 3em;
        gap: 3em;
        align-items: center;
    }
}

.custom-shape-divider-top-1605469539 {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    margin-bottom: -50px;
    z-index: 2;
}

.custom-shape-divider-top-1605469539 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
}

.custom-shape-divider-top-1605469539 .shape-fill {
    fill: $purple;
}