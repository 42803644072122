$pink: #DD7373;
$purple: #3B3561;
$cyan: #D6EBEB;
$yellow: #a1ff82;
$yellow-dark: #d6c745;


$purple: #2C4251;
$purple-dark: #1D2B35;

// 00CC66 2274A5


$medium-screen: 600px;
$big-screen: 1000px;

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: $cyan;
}

.text-small {
  font-size: 12px;
}

.text-stylish {
  font-style: italic;
  font-family: "Grandstander", serif;
  margin-top: 0;
}