@import "../../index.scss";

.settings-container,
.settings-content-container {
    background-color: white;
    color: black;
}

.settings-content-container,
.settings-title-container {
    padding: 20px;
}

.settings-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:hover {
        cursor: pointer;
    }

    h3 {
        margin: 0;
    }

    p {
        margin: 0;
        margin-top: 10px;
    }
}
