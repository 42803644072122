@import "./index.scss";
.dashboard-content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    gap: 1em;
    margin: 1em;
}

@media only screen and (min-width: 600px) {
    .dashboard-content {
        margin: 3em;
        gap: 3em;
    }
}

@media only screen and (min-width: 1000px) {
    .dashboard-content {
        grid-template-columns: auto 350px;
        margin: 3em;
        gap: 3em;
    }
}
