@import '../../index.scss';

.footer-container {
    padding: 3em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;

    a {
        text-decoration: none;
        color: $purple;
    }
}

.footer-wrapper {
    background-color: $yellow;

    display: grid;
    padding: 3em;
}