@import "../../index.scss";

.header {
    color: black;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;


    h1,
    p {
        margin: 0;
        padding: 0;
    }

    h1 {
        font-family: "Grandstander", serif;
        color: $purple;
    }
}

.logo-container {
    background-color: $yellow;

    padding: 30px 20px;
    background-color: #a1ff82;

    &:hover {
        cursor: pointer;
    }
}
