.activity-filter-container {

    p {
        margin: 0;
    }
}

.activity-filter-item {
    display: flex;
    align-items: center;
}