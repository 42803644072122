.color-circle {
    background-color: transparent;
    width: 50px;
    height: 50px;
    &:hover {
        cursor: pointer;
    }
}

.circle-button {
    border: none;
    background-color: transparent;
    padding: 0;
}
