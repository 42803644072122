.share-container {
    display: flex;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

.share-content-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
}

.share-left-container {
    display: flex;
    gap: 10px;
}
