@import "index.scss";

.line-container {
    margin: 1em;
    margin-top: 1em;
}

.top-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 1em;
}

.btn-container {
    display: flex;
    justify-content: center;
    margin: 3em;
    background-color: transparent;
}

.wrapper-container {
    margin: 0 3em;
}

.to-home-button {
    background-color: $purple;
    color: white;
    padding: 1.2em;
    font-size: 18px;
}

@media only screen and (min-width: $medium-screen) {
    .top-container {
        margin: 0 3em;
    }
    .line-container {
        margin: 3em;
        margin-top: 1em;
    }
}
