@import "../../index.scss";

.mapx-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $purple;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 10px 20px 10px 20px;


    font-size: 15px;

    &:hover {
        cursor: pointer;
        background-color: $purple-dark;
        color: white;
    }
}

.icon-button {
    color: black;
    background-color: white;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        color: $purple;
        background-color: transparent;
    }
}
