@import "../../index.scss";

.theme-picker-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
}

@media only screen and (min-width: $medium-screen) {
    .theme-picker-container {
        grid-template-columns: repeat(8, 1fr);
    }
}

@media only screen and (min-width: $big-screen) {
    .theme-picker-container {
        grid-template-columns: repeat(5, 1fr);
    }
}